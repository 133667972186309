import { Link, useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { UserAuthContext } from "../../../../../context/AuthContext";
import { links } from "../../../../routes";
import { MenuLayout } from "../../../../layout";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import {  getProduct, reset, updateProduct, URIA } from "../../../../../app/controller/product/product.slice";
import { DescriptionField } from "../Create";
import { AlertModule, Button, Textfield, Toast } from "../../../../components";
import { MeditationCuate } from "../../../../assets/illustration";
import { slugifyFileName } from "../../../../utils/functions";
import axios from "axios";

const EditFilesContain = ({item}:{item: any}) => {
  const { token } = useContext(UserAuthContext);
  

  const [error, setError] = useState<any>(null)
  const [perc, setPerc] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)


  const [images, setImages] = useState<any[]>(item?.images?.filter((el: any) => el !== null));
  const [videos, setVideos] = useState<any[]>(item?.videos.filter((el: any) => el !== null));


  const removeFile = async (id: string, url: string, type: string) => {
    setLoading(true)
    setPerc(0)
    try {
      const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent?.total
          );
          setPerc(percentCompleted)
          if(percentCompleted === 100){
            setLoading(false)
          }
          console.log(`Upload Progress: ${percentCompleted}%`);
        },
      }
      const response = await axios.delete(`${URIA}/file?id=${id}&url=${url}`, config)
      // console.log("premiere", response)
      if(response.data === "Successfully deleted"){
        if(type === "images"){
          setImages(images.filter((el: any) => el !== url))
        }else{
          if(type === "videos"){
            setVideos(videos.filter((el: any) => el !== url))
          }
        }
        setLoading(false)
      }
    } catch (er: any) {
      setLoading(false)
      setError({
        field: "global",
        message: er.message
      })
    }
  }

  const addFileToProduct = async (id: string, fileName: string, file: any, type: string) => {
    try{
      setPerc(0)
      const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
      }
      const response = await axios.put(`${URIA}/files/addfile?id=${id}&fileName=${fileName}`, {}, config)

      setLoading(true)
      if(response.data){
        const uploadResponse = await axios.put(response.data, file, {
          headers: {
            "Content-Type": "application/png",
          },

          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent?.total
            );
            setPerc(percentCompleted)
            if(percentCompleted === 100){
              setLoading(false)
            }
            console.log(`Upload Progress: ${percentCompleted}%`);
          },
        });
        if(uploadResponse.status === 200){
          if(type === "cover"){
            setImages([response.data, ...images])
          }else{
            if(type === "images"){
              setImages([...images, URL.createObjectURL(file)])
            }else{
              if(type === "videos"){
                setVideos([...videos, URL.createObjectURL(file)])
              }
            }
          }
          
        }
        // console.log("seconde", uploadResponse)
      }
    }catch(er: any) {
      console.log(er)
      setError({
        field: "global",
        message: er.message
      })
    }
  }


  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
    const files = e.target.files
   
    if(files && files.length > 0){
      const file = files[0]
      let type: number = file.size
      let max: number = 1024 * 1024 * 4
      if(type < max){
          let info = {
            name: slugifyFileName(file.name),
            size: file.size,
            type: file.type
          }
          setLoading(true)
          addFileToProduct(item?.id, info.name, file, "cover")
          

      }else{
        setError({
            field: "global",
            message: "Votre fichier ne doit pas dépasser 4Mo"
        })
        return
      }
      setError(null)
    }
  }

  const handleMultiChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
    const file = e.target.files
    if(file && file.length > 0){
       let tabs: File[] = Object.values(file)
        if(tabs.length < 7){
            tabs?.forEach((el) => {
                let type: number = el.size
                let max: number = 1024 * 1024 * 4
                if(type < max){
                    let info = {
                      name: slugifyFileName(el.name),
                      size: el.size,
                      type: el.type
                    }
                    setLoading(true)
                    addFileToProduct(item?.id, slugifyFileName(el.name), el, "images")
                    
                }else{
                  setError({
                    field: "global",
                    message: "Votre fichier ne doit pas dépasser 4Mo"
                  })
                  // return
                }
            })
            setError({
              field: "",
                message: ""
            })
        }else{
          setError({
            field: "multi-image",
            message: "Vous ne devez pas dépasser 6 images"
          })
        }
    }
  }

  const handleMultiChangeVideoFile = (e: ChangeEvent<HTMLInputElement>) =>{
    const file = e.target.files
    if(file && file.length > 0){
       let tabs: File[] = Object.values(file)
        if(tabs.length < 4){
            tabs?.forEach((el) => {
                let type: number = el.size
                let max: number = 1024 * 1024 * 512
                if(type < max){
                    setLoading(true)
                    addFileToProduct(item?.id, slugifyFileName(el.name), el, "videos")
                    
                }else{
                  setError({
                    field: "global",
                    message: "Votre fichier ne doit pas dépasser 512Mo"
                  })
                  // return
                }
            })
            setError({
              field: "",
                message: ""
            })
        }else{
            setError({
              field: "multi-video",
                message: "Vous ne devez pas dépasser 3 videos"
            })
        }
    }
  }


  return (
    <>
      <div className='kbn-page-form__container'>
        <div className='kbn-page-form__contain'>
          <h2>Image de couverture</h2>
        </div>
        <div className={"kbn-mulit-file"}>
          <input type="file" accept='.jpg, .png,.jpeg' id='imageImg' onChange={handleChangeFile}/>
          <div className='kbn-mulit-file__grid'>
            {
              images && images[0] ? (
                <div className={"kbn-mulit-file-preview"}>
                  <img src={images[0]} alt="article cover"/>
                  <button type="button" className={"kbn-mulit-file-preview__btn is--danger"} onClick={() => {
                    removeFile(item?.id, images[0], "images")
                  }}>
                    <i className="icon-trash"></i>
                  </button>
                </div>
              ) : (
                <label htmlFor="imageImg" className={"kbn-mulit-file-btn"}>
                  <i className=" icon-picture-gallery"></i>
                  {
                    error?.field === "multi-image" ? (
                      <span style={{color: "red"}}>{error?.message}</span>
                    ) : (
                      <span>Cliquer pour changer l'image de couverture du produit à vendre </span>
                    )
                  }
                </label>
              )
            }
          </div>
        </div>
      </div>

      <div className='kbn-page-form__container'>
        <div className='kbn-page-form__contain'>
          <h2>Images</h2>
        </div>
        <div className={"kbn-mulit-file"}>
          <input type="file" accept='.jpg, .png,.jpeg' multiple={false} id='imagesImg' onChange={handleMultiChangeFile}/>
          <div className='kbn-mulit-file__grid'>
            {
                  images.slice(1, (images.length + 1)).length > 0 ? (
                      images.slice(1, (images.length + 1)).length > 4 ? (
                          images.slice(1, (images.length + 1)).map((image, index) => (
                              <div className={"kbn-mulit-file-preview"} key={index}>
                                  <img src={image} alt="article"/>
                                  <button type="button" className={"kbn-mulit-file-preview__btn is--danger"} onClick={() => {
                                      removeFile(item?.id, image, "images")
                                  }}>
                                      <i className="icon-trash"></i>
                                  </button>
                              </div>
                          ))
                      ) : (
                          <>
                              {
                                  images.slice(1, (images.length + 1)).map((image, index) => (
                                      <div className={"kbn-mulit-file-preview"} key={index}>
                                          <img src={image} alt="projet"/>
                                          <button type="button" className={"kbn-mulit-file-preview__btn is--danger"} onClick={() => {
                                            removeFile(item?.id, image, "images")
                                          }}>
                                              <i className="icon-trash"></i>
                                          </button>
                                      </div>
                                  ))
                              }
                              <label htmlFor="imagesImg" className={"kbn-mulit-file-preview_btn"}>
                                  <i className="icon-add-square"></i>
                                  <span>Vous pouvez ajouter {5 - images.slice(1, (images.length + 1)).length} image(s) si vous le voulez</span>
                              </label>
                          </>
                      )
                  ) : (
                      <label htmlFor="imagesImg" className={"kbn-mulit-file-btn"}>
                          <i className=" icon-picture-gallery"></i>
                          {
                              error?.field === "multi-image" ? (
                                  <span style={{color: "red"}}>{error?.message}</span>
                              ) : (
                                  <span>Cliquer pour changer des images du produit à vendre (5 max - 4Mo max par image) </span>
                              )
                          }

                      </label>
                  )
            }
          </div>
        </div>
      </div>
      <div className='kbn-page-form__container'>
        <div className='kbn-page-form__contain'>
          <h2>Videos</h2>
        </div>
        <div className={"kbn-mulit-file"}>
          <input type="file"  accept='.mp4, .mov, .avi' multiple={false} id='videosImg' onChange={handleMultiChangeVideoFile}/>
          <div className='kbn-mulit-file__grid'>
            {
              videos.length > 0 ? (
                videos.length > 2 ? (
                    videos.map((image, index) => (
                          <div className={"kbn-mulit-file-preview"} key={index}>
                              <video src={image} controls/>
                              <button type="button" className={"kbn-mulit-file-preview__btn is--danger"} onClick={() => {
                                  removeFile(item?.id, image, "videos")
                              }}>
                                  <i className="icon-trash"></i>
                              </button>
                          </div>
                      ))
                  ) : (
                      <>
                          {
                              videos.map((image, index) => (
                                  <div className={"kbn-mulit-file-preview"} key={index}>
                                      
                                      <video src={image} controls/>
                                      <button type="button" className={"kbn-mulit-file-preview__btn is--danger"} onClick={() => {
                                        removeFile(item?.id, image, "videos")
                                      }}>
                                          <i className="icon-trash"></i>
                                      </button>
                                  </div>
                              ))
                          }
                          <label htmlFor="videosImg" className={"kbn-mulit-file-preview_btn"}>
                              <i className="icon-add-square"></i>
                              <span>Vous pouvez ajouter {3 - videos.length} videos(s) si vous le voulez</span>
                          </label>
                      </>
                  )
              ) : (
                  <label htmlFor="videosImg" className={"kbn-mulit-file-btn"}>
                      <i className=" icon-picture-gallery"></i>
                      {
                          error?.field === "multi-image" ? (
                              <span style={{color: "red"}}>{error?.message}</span>
                          ) : (
                              <span>Cliquer pour changer des vidéo du produit à vendre (3 max - 512Mo max par image) </span>
                          )
                      }

                  </label>
              )
            }
          </div>
        </div>
      </div>
      {
        loading ? (
          <div className={`kbn-toast is--open`}>
            <div className={`kbn-toast-contain`}>
              <div className='kbn-toast-contain__icon'>
                  <i className="icon-spin"></i>
              </div>
              <div className='kbn-toast-contain__container'>
                {"Action en cours... " + `${perc}%`}
              </div>
            </div>
        </div>
        ) : null
      }
      
    </>
  )
}

const EditInformationContain = ({item}:{item: any}) => {
  const [data, setData] = useState<any>({
    id: item.id,
    label: item.label, 
    minPrice: item.minPrice,
    maxPrice: item.maxPrice, 
    quantity: item.quantity, 
    supplier: item.supplier, 
  });
  const {isEditError, isEditLoading, isEditSuccess, message} = useAppSelector((state) => state.product);
  const dispatch = useAppDispatch();
  const [error, setError] = useState<{ field: string, message: string }>({
    field: "",
    message: ""
  })
  const [openC, setOpenC] = useState<boolean>(false);
  const [description, setDescription] = useState<any>(item.description || "");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!data.label){
        setError({
            field: "label",
            message: "Veuillez renseigner le titre"
        })
        return
    }
    if(!description){
        setError({
          field: "description",
          message: "Veuillez renseigner la description"
        })
        return
    }
    if(!data.minPrice){
        setError({
          field: "minPrice",
            message: "Veuillez choisir la catégorie"
        })
        return
    }
    if(!data.maxPrice){
        setError({
          field: "maxPrice",
            message: "Veuillez choisir la catégorie"
        })
        return
    }
    if(data.maxPrice < data.minPrice){
        setError({
          field: "global",
          message: "Le prix minimum doit etre inferieur au prix maximum "
        })
        return
    }
    // setLoading(true)
    setOpenC(true)
    let datas = {
        ...data,
        description,
    }
    dispatch(updateProduct(datas))
  }

  return (
    <form onSubmit={handleSubmit} className='kbn-page-form'>
      {
        isEditError && openC ? (
            <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
        ) : (
          isEditSuccess && openC ? (
            <AlertModule status={"st-success"} title={"Ouppss!!!"} message={"Les modifications ont été enregistrées"}/>
          ): (
            error && error?.field === "global" ? (
              <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={error?.message}/>
            ) : null
          )
        )
      }
      <div className='kbn-page-form__container'>
        <div className='kbn-page-form__contain'>
          <h2>Informations</h2>
        </div>
        <div className='kbn-page-form__fields'>
          <div className='kbn-page-form__field'>
            <Textfield
                id={"label"}
                value={data.label}
                require={true}
                changeValue={(value: any) => setData({...data, label: value})}
                type={"text"}
                label={"Titre"}
                placeholder={"Meuble"}
                error={error}
            />
          </div>
          <div className='kbn-page-form__field'>
            <Textfield
                id={"minPrice"}
                value={data.minPrice}
                require={true}
                changeValue={(value: any) => setData({...data, minPrice: value})}
                type={"number"}
                label={"Prix minimun"}
                placeholder={"20000"}
                min={0}
                error={error}
            />
          </div>
          <div className='kbn-page-form__field'>
            <Textfield
                id={"maxPrice"}
                value={data.maxPrice}
                require={true}
                changeValue={(value: any) => setData({...data, maxPrice: value})}
                type={"number"}
                label={"Prix maximun"}
                placeholder={"25000"}
                min={parseInt(data.minPrice) || 0}
                error={error}
            />
          </div>
          <div className='kbn-page-form__field'>
            <Textfield
              id={"quantity"}
              value={data.quantity}
              require={false}
              changeValue={(value: any) => setData({...data, quantity: value})}
              type={"number"}
              label={"Quantité"}
              placeholder={"Entrer la quantité"}
              error={error}
              min={0}
            />
          </div>
          <div className='kbn-page-form__field'>
            <Textfield
              id={"supplier"}
              value={data.supplier}
              require={false}
              changeValue={(value: any) => setData({...data, supplier: value})}
              type={"text"}
              label={"Fournisseur"}
              placeholder={"Entrer le nom du fournisseur"}
              error={error}
            />
          </div>
        </div>
      </div>
      <div className='kbn-page-form__container'>
        <div className='kbn-page-form__contain'>
          <h2>Description</h2>
        </div>
        <div className='kbn-page-form__fields'>
          <DescriptionField 
            description={description} 
            error={error} 
            require={true} 
            fnc={(val: any) => setDescription(val)}
          />
        </div>
      </div>
      <div className='kbn-page-form__action'>
          <Button 
              btnType={'submit'} 
              label={isEditLoading ? 'Modification en cours...' : "Enregistrer les modifications"} 
              btnStyle={'is--primary'}
              active={!isEditLoading}
              withIconLeft={isEditLoading} 
              iconLeftClass={"icon-spin"}
          />
      </div>
    </form>
  )
  
}

export const ProductEditPage = () => {
    const {slug} = useParams()
    const { token } = useContext(UserAuthContext);
    let navigate = useNavigate()

    useEffect(() => {
      if(!token){
        navigate(links.login + `?url=${links.product_edit + slug}`)
      }
    }, [token, navigate, slug]);
    
    const dispatch = useAppDispatch();
    const { isLoading, isSuccess, isError, product, message } = useAppSelector((state) => state.product);

    useEffect(() => {
      if(slug){
        dispatch(getProduct(slug))
      }
      return () => {
        dispatch(reset())
      }
    }, [dispatch, slug]);
    
    return (
      <MenuLayout title="Product" isMin={true} here="product">
        <div className='kbn-page'>
          <div className='kbn-page-arianne'>
            <Link to={links.home}>
              <span>Accueil</span>
            </Link>
            <span>/</span>
            <Link to={links.products}>
              <span>Produit</span>
            </Link>
            <span>/</span>
            <Link to={links.product + slug}>
              <span>Aperçu du Produit</span>
            </Link>
            <span>/</span>
            <p className='active'>
              <span>Modification d'un produit</span>
            </p>
          </div>
          {
            isLoading ? (
              <div className={"kbn-loader2"}>
                <i className="icon-spin"/>
              </div>
            ) : (
              isError? (
                <div className={"kbn-empty-status"}>
                  <div className='kbn-empty-status__illu'>
                    <MeditationCuate/>
                  </div>
                  <div className='kbn-empty-status__container'>
                    <strong>Oupss!! Une erreur est survenue</strong>
                    {message}
                    <button type='button' onClick={() => window.location.reload()} style={{textDecoration: "underline"}}>Reactualiser...</button>
                  </div>
                </div>
              ) : (
                isSuccess && product ? (
                  <>
                    <div className='kbn-page-title'>
                      <h1>Modification de produit</h1>
                    </div>
                    <div className='kbn-page__contain kbn-page-contain'>
                      <EditFilesContain item={product}/>
                      <EditInformationContain item={product}/>
                    </div>
                  </>
                ) : null
              )
            )
          }
        </div>
      </MenuLayout>
    )
}
  