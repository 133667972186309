import { Link, useNavigate } from "react-router-dom";

import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { UserAuthContext } from "../../../../../context/AuthContext";
import { links } from "../../../../routes";
import { MenuLayout } from "../../../../layout";
import { deleteProduct, getProducts, importProduct, reset } from "../../../../../app/controller/product/product.slice";
import { useAppDispatch, useAppSelector } from "../../../../../app/store";
import { AlertModule, Button, ModalLayout } from "../../../../components";
import { MeditationCuate } from "../../../../assets/illustration";
import { getCategories } from "../../../../../app/controller/category/category.slice";
import { formatPrice, slugifyFileName } from "../../../../utils/functions";


const DeleteModal = ({item, closeFnc}:{item: any, closeFnc: (val: boolean) => void}) => {
  const dispatch = useAppDispatch();
  const {isDeleteSuccess, isDeleteLoading, isDeleteError, message } = useAppSelector((state) => state.product);
  
  const [statut, setStatut] = useState<boolean>(false)
  const closeModal = () => {
    if(isDeleteSuccess && statut){
      // window.location.reload()
    }
    setStatut(false)
    closeFnc(false)
  }

  return (
    
    <ModalLayout size={"is--md"} isOpen={true} id={"add-modal"} withClose={!isDeleteLoading} fnc={() => closeModal()}>
      <div className={"kbn-modal-form"}>
        {
          statut && isDeleteSuccess ? (
            <div className={"kbn-modal-form-status"}>
                <div className={"kbn-modal-form-status__container"}>
                    <div className={"kbn-modal-form-status__icon is--success"}>
                        <i className="icon-party-bell"/>
                    </div>
                    <div className={"kbn-modal-form-status__text"}>
                        <h2>Suppression d'un produit</h2>
                        <p>L'element a bien été supprimé avec succès</p>
                        <div className={"kbn-modal-form-status__action"}>
                            <Button
                                btnType={"button"}
                                label={ "Fermer..."}
                                btnStyle={"is--neutral"}
                                active={true}
                                withIconLeft={false}
                                onClick={() => {
                                    closeModal()
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
          ) : (
            <div className={"kbn-modal-form-status"}>
                <div className={"kbn-modal-form-status__container"}>
                    <div className={"kbn-modal-form-status__icon is--danger"}>
                      <i className="icon-box-archive"/>
                    </div>
                    <div className={"kbn-modal-form-status__text"}>
                        <h2>Suppression d'un produit</h2>
                        {
                            statut && isDeleteError ? (
                              <p>
                                  <strong>Ouppss!!!</strong> <br/>
                                  <span>{message}</span>
                              </p>
                            ) : (
                                <p>Souhaitez-vous vraiment archiver cet élément</p>
                            )
                        }
                        <div className={"kbn-modal-form-status__action"}>
                            {
                                statut && isDeleteError ? (
                                    <Button
                                        btnType={"button"}
                                        label={isDeleteLoading ? "Suppression en cours" : "Réessayer..."}
                                        btnStyle={"is--danger"}
                                        active={!isDeleteLoading}
                                        withIconLeft={true}
                                        iconLeftClass={isDeleteLoading ? "icon-spin" : "icon-box-archive"}
                                        onClick={() => {
                                          
                                          setStatut(true)
                                          dispatch(deleteProduct(item.id))
                                        }}
                                    />
                                ) : (
                                    <Button
                                        btnType={"button"}
                                        label={isDeleteLoading ? "Suppression en cours" : "Archiver"}
                                        btnStyle={"is--danger"}
                                        active={!isDeleteLoading}
                                        withIconLeft={true}
                                        iconLeftClass={isDeleteLoading ? "icon-spin" : "icon-box-archive"}
                                        onClick={() => {
                                          
                                          setStatut(true)
                                          dispatch(deleteProduct(item.id))
                                        }}
                                    />
                                )
                            }

                        </div>
                    </div>
                </div>
            </div>
          )
        }
      </div>
    </ModalLayout>
  )
}

const ImportModal = ({closeFnc}:{closeFnc: (val: boolean) => void}) => {
  const { isImportLoading, isImportError, isImportSuccess, message } = useAppSelector((state) => state.product);
  const dispatch = useAppDispatch();

  const [fichier, setFichier] = useState<any>(null);
  const [fichierName, setFichierName] = useState<any>(null);
  const [isLoading, setIsloading] = useState<boolean>(false)
  const [closeM, setCloseM] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)

  const handleFileChangeFile = (e: ChangeEvent<HTMLInputElement>) =>{
    const file = e.target.files
    if(file && file.length > 0){
      let type: number = file[0].size
      let max: number = 1024 * 1024 * 25
      if(type < max){
        let info = {
          name: slugifyFileName(file[0].name),
          size: file[0].size,
          type: file[0].type
        }
          setFichierName(info.name)
          setFichier(file[0])
      }else{
          setError({
              field: "global",
              message: "Votre fichier ne doit pas dépasser 8Mo"
          })
          return
      }
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!fichier){
      setError({
        field: "global",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    const formData = new FormData();
    formData.append('file', fichier);
    setCloseM(true)
    setIsloading(true)
    dispatch(importProduct(formData))
  }


  
  const closeModal = () => {
    setFichier(null)
    closeFnc(false)
    setCloseM(false)
  }

  return (
    <ModalLayout size={"is--md"} id={"add-symptom"} fnc={() => closeModal()} isOpen={true} withClose={!isImportLoading}>
        {
          isImportSuccess && closeM ? (
            <div className={"kbn-modal-form-status"}>
                <div className={"kbn-modal-form-status__container"}>
                    <div className={"kbn-modal-form-status__icon is--success"}>
                        <i className="icon-party-bell"/>
                    </div>
                    <div className={"kbn-modal-form-status__text"}>
                        <h2>Importation des produits</h2>
                        <p>Le fichier a bien été importé</p>
                          <div className={"kbn-modal-form-status__action"}>
                              <Button
                                  btnType={"button"}
                                  label={"Fermer..."}
                                  btnStyle={"is--neutral"}
                                  active={true}
                                  withIconLeft={false}
                                  onClick={() => {
                                      closeModal()
                                  }}
                              />
                          </div>
                    </div>
                </div>
            </div>
          ) : (
            <div className={"kbn-modal-form"}>
              <div className={"kbn-modal-form__header"}>
                  <h2>Importation de produit</h2>
              </div>
              <form className='kbn-modal-form__container' onSubmit={handleSubmit}>
                  {
                    closeM && isImportError ? (
                      <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={message}/>
                    ) : (
                      error && error.field === "global" && (
                        <AlertModule status={"st-danger"} title={"Ouppss!!!"} message={error.message}/>
                      )
                    )
                  }
                  <div className={"kbn-modal-form__fields"}>
                      
                      <div className={"kbn-modal-form__field"}>
                        <div className={"kbn-filefield"}>
                            <input type="file" id='pdfImg' accept=".csv" onChange={handleFileChangeFile}/>
                            <label htmlFor="pdfImg">
                                <div className={`kbn-filefield__label force--open`}>
                                  {
                                    fichier ? (
                                      <>
                                        <i className={"icon-upload"}/>
                                        <p>
                                            <strong>{fichier?.name}</strong> <br/>
                                            <span>Cliquer pour changer le fichier csv (25Mo max)</span>
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        <i className={"icon-upload"}/>
                                        <span>Cliquer pour changer le fichier csv (25Mo max)</span>
                                      </>
                                    )
                                  }

                                </div>
                            </label>
                        </div>
                      </div>

                      
                  </div>
                  <div className={"kbn-modal-form__action"}>
                      <Button 
                          btnType={"submit"} 
                          btnStyle={"is--primary"} 
                          label={isImportLoading ? "Importation en cours..." : "Importer"} 
                          active={!isImportLoading} />
                  </div>
              </form>
            </div>
          )
        }
        
      </ModalLayout>
  )
}

export const ProductsPage = () => {
    const { token } = useContext(UserAuthContext);
    let navigate = useNavigate()

    useEffect(() => {
      if(!token){
        navigate(links.login + `?url=${links.products}`)
      }
    }, [token, navigate]);
    
    const [search, setSearch] = useState<string>("")
    const [searchCaregry, setSearchCategory] = useState<string>("")

    const [numberItem, setNumberItem] = useState<number>(20)
    const [page, setPage] = useState<number>(1)
    
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const [importModal, setImportModal] = useState<boolean>(false)

    const [itemModal, setItemModal] = useState<any>(null)
    const dispatch = useAppDispatch();
    const [chooseApercu, setChosseApercu] = useState<string>("grid")
    
    const { isLoading, isSuccess, isError, products, products_info, message } = useAppSelector((state) => state.product);
    const { categories } = useAppSelector((state) => state.category);
  
    const onChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
      let value = e.target.value
      setSearchCategory(value)
    }

    useEffect(() => {
      if(page && page >= 0 && numberItem && numberItem > 1){
        dispatch(getProducts({
          page: page - 1,
          size: numberItem,
          categoryId: searchCaregry
        }))
        dispatch(getCategories({
          page: 0,
          size: 500,
        }))
      }
      return () => {
          dispatch(reset())
      }
    }, [dispatch, page, numberItem, searchCaregry])

    return (
      <MenuLayout title="Products" isMin={true}>
        <div className='kbn-page'>
          <div className='kbn-page-arianne'>
            <Link to={links.home}>
              <span>Accueil</span>
            </Link>
            <span>/</span>
            <p className='active'>
              <span>Produits</span>
            </p>
          </div>
          <div className='kbn-page-title'>
            <h1>Les produits</h1>
          </div>
          <div className='kbn-page-actions'>
            <div className='kbn-page-actions__main'>
              <div className='kbn-page-action'>
                <Button btnType={'button'} label={'Ajouter'} btnStyle={'is--primary'} active={true} withIconRight={true} iconRightClass=' icon-plus' onClick={() => navigate(links.product_create)}/>
              </div>
              <div className='kbn-page-action'>
                <Button btnType={'button'} label={'Importer via csv'} btnStyle={'is--secondary'} active={true} withIconLeft={false} iconLeftClass=' icon-plus' onClick={() => setImportModal(true)}/>
              </div>
              <div className='kbn-page-action-select'>
                <select value={searchCaregry} onChange={onChangeSelect}>
                  <option value="">Choisir une categorie</option>
                  {
                    categories ? (
                      categories?.filter((el: any) => el.parent).length > 0 ? (
                        categories.filter((el: any) => el.parent).map((item: any, index: number) => (
                          <option value={item.id} key={index}>{item?.label}</option>
                        ))
                      ) : null
                    ) : null
                  }
                </select>
              </div>
              <div className='kbn-page-search'>
                <i className="icon-search-alt"></i>
                <input type="search" placeholder='Rechercher....' value={search} onChange={(e) => setSearch(e.target.value)}/>
              </div>
            </div>
            <div className='kbn-page-actions__side'>
              <div className='kbn-page-numbers'>
                <span>Nombre à afficher</span>
                <input type="number" value={numberItem} onChange={(e) => setNumberItem(parseInt(e.target.value))} min={2} />
              </div>
              {
                products_info?.totalPages > 1 ? (
                  <div className='kbn-page-numbers'>
                    <span>Page: </span>
                    <input type="number" value={page} onChange={(e) => setPage(parseInt(e.target.value))} min={1} />
                    <span>/ {products_info?.totalPages || ""}</span>
                  </div>
                ) : (
                  <div className='kbn-page-numbers'>
                    <span>Page: 1/1</span>
                  </div>
                )
              }
              <div className='kbn-page-btns'>
                <button type='button' onClick={() => setChosseApercu("grid")} className={`kbn-page-btn ${chooseApercu === "grid" ? "is--active" : ""}`}>
                  <i className="icon-grid"></i>
                </button>
                <button type='button' onClick={() => setChosseApercu("list")} className={`kbn-page-btn ${chooseApercu === "list" ? "is--active" : ""}`}>
                  <i className="icon-list-ul-alt"></i>
                </button>
              </div>
            </div>
          </div>
          {
            isLoading ? (
              <div className={"kbn-loader2"}>
                <i className="icon-spin"/>
              </div>
            ) : (
              isError ? (
                <div className={"kbn-empty-status"}>
                  <div className='kbn-empty-status__illu'>
                    <MeditationCuate/>
                  </div>
                  <div className='kbn-empty-status__container'>
                    <strong>Oupss!! Une erreur est survenue</strong>
                    {message}
                    <button type='button' onClick={() => window.location.reload()} style={{textDecoration: "underline"}}>Reactualiser...</button>
                  </div>
                </div>
              ) : (
                isSuccess && products && products_info ? (
                  <>
                    {
                      products
                      .filter((el: any) => el?.label?.toLowerCase().includes(search.toLowerCase()))
                      .length > 0 ? (
                        chooseApercu === "grid" ? (
                          <>
                          {
                            products
                            .filter((el: any) => el.images?.length < 1 )
                            .filter((el: any) => el?.label?.toLowerCase().includes(search.toLowerCase()))
                            .length > 0 ?(
                              <>
                              <br />
                              <strong>Produits sans image</strong>
                              <div className='kbn-page-grid'>
                                {
                                products
                                  .filter((el: any) => el.images?.length < 1 )
                                  .filter((el: any) => el?.label?.toLowerCase().includes(search.toLowerCase()))
                                  .map((item, index) => (
                                    <div className='kbn-page-grid-item' key={index}>
                                      <div className='kbn-page-card'>
                                        <div className='kbn-page-card__avatar is--square'>
                                          {
                                            item?.images?.length > 0 ? (
                                              <img src={item?.images[0]} alt="avatar"/>
                                            ) : (
                                              <i className="icon-image-square"></i>
                                            )
                                          }
                                        </div>
                                        <div className='kbn-page-card__container'>
                                          <strong>{item?.label}</strong>
                                          <div><span className="kbn-tag">{item.category?.label}</span></div>
                                          <p>Prix: de {formatPrice(item.minPrice, "yuan")} à {formatPrice(item.maxPrice, "yuan")}</p>
                                          <div>Etat: <span className={`kbn-chip ${item.status === "ACTIVE" ? "is--success" : "is--warning"}`}>{item.status}</span></div>

                                        </div>
                                        <div className='kbn-page-card__actions'>
                                          <button type='button' className='kbn-page-card__action' onClick={() => navigate(links.product + item.id)}>
                                            <i className="icon-pen-circle"></i>
                                            <span>Voir l'apercu</span>
                                          </button>
                                        
                                          <button type='button' className='kbn-page-card__action is--danger' onClick={() => {
                                            setItemModal(null)
                                            setItemModal(item)
                                            setDeleteModal(true)
                                          }}>
                                            <i className="icon-box-archive"></i>
                                            <span>Archiver</span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                }
                              </div>
                              </>
                            ) : null
                          }
                          {
                            products
                            .filter((el: any) => el.images?.length > 1 )
                            .filter((el: any) => el?.label?.toLowerCase().includes(search.toLowerCase()))
                            .length > 0 ? (
                              <>
                              <br />
                              <strong>Autres</strong>
                              <div className='kbn-page-grid'>
                                {
                                products
                                  .filter((el: any) => el.images?.length > 0 )
                                  .filter((el: any) => el?.label?.toLowerCase().includes(search.toLowerCase()))
                                  .map((item, index) => (
                                    <div className='kbn-page-grid-item' key={index}>
                                      <div className='kbn-page-card'>
                                        <div className='kbn-page-card__avatar is--square'>
                                          {
                                            item?.images?.length > 0 ? (
                                              <img src={item?.images[0]} alt="avatar"/>
                                            ) : (
                                              <i className="icon-image-square"></i>
                                            )
                                          }
                                        </div>
                                        <div className='kbn-page-card__container'>
                                          <strong>{item?.label}</strong>
                                          <div><span className="kbn-tag">{item.category?.label}</span></div>
                                          <p>Prix: de {formatPrice(item.minPrice, "yuan")} à {formatPrice(item.maxPrice, "yuan")}</p>
                                          <div>Etat: <span className={`kbn-chip ${item.status === "ACTIVE" ? "is--success" : "is--warning"}`}>{item.status}</span></div>
                                        </div>
                                        <div className='kbn-page-card__actions'>
                                          <button type='button' className='kbn-page-card__action' onClick={() => navigate(links.product + item.id)}>
                                            <i className="icon-pen-circle"></i>
                                            <span>Voir l'apercu</span>
                                          </button>
                                        
                                          <button type='button' className='kbn-page-card__action is--danger' onClick={() => {
                                            setItemModal(null)
                                            setItemModal(item)
                                            setDeleteModal(true)
                                          }}>
                                            <i className="icon-box-archive"></i>
                                            <span>Archiver</span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                }
                              </div>
                              </>
                            ) : null
                          }
                          </>
                        ) : (
                          <div className='kbn-page-list'>
                            <div className='kbn-page-list__row kbn-page-list-row is--header'>
                              <div className='kbn-page-list-col is--min'>
                                <strong>#</strong>
                              </div>
                              <div className='kbn-page-list-col is--auto'>
                                <strong>Produit</strong>
                              </div>
                              <div className='kbn-page-list-col is--auto'>
                                <strong>Categorie</strong>
                              </div>
                              <div className='kbn-page-list-col is--mid'>
                                <strong>Statut</strong>
                              </div>
                              <div className='kbn-page-list-col is--mid'>
                                <strong>Actions</strong>
                              </div>
                            </div>
                            <div className='kbn-page-list__body kbn-page-list-body'>
                              {
                                products
                                .filter((el: any) => el?.label?.toLowerCase().includes(search.toLowerCase()))
                                .map((item, index) => (
                                  <div className='kbn-page-list-row' key={index}>
                                    <div className='kbn-page-list-col is--min'>
                                      <strong>{index + 1}</strong>
                                    </div>
                                    <div className='kbn-page-list-col is--auto'>
                                      <div className='kbn-page-list-user'>
                                        <div className='kbn-page-list-user__avatar  is--square'>
                                          {
                                            item?.images?.length > 0 ? (
                                              <img src={item?.images[0]} alt="avatar"/>
                                            ) : (
                                              <i className="icon-image-square"></i>
                                            )
                                          }
                                        </div>
                                        <div className='kbn-page-list-user__container'>
                                            <strong>{item?.label}</strong>
                                            <p>Prix: de {formatPrice(item.minPrice, "yuan")} à {formatPrice(item.maxPrice, "yuan")}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='kbn-page-list-col is--auto'>
                                      <div><span className="kbn-tag">{item.category?.label}</span></div>
                                    </div>
                                    <div className='kbn-page-list-col is--mid'>
                                      <div><span className={`kbn-chip ${item.status === "ACTIVE" ? "is--success" : "is--warning"}`}>{item.status}</span></div>
                                    </div>
                                    <div className='kbn-page-list-col is--mid'>
                                      <button type='button' className='kbn-page-list-btn' onClick={() => navigate(links.product + item?.id)}>
                                        <i className="icon-pen-circle"></i>
                                        <span>Consulter le produit</span>
                                      </button>
                                      <button type='button' className='kbn-page-list-btn is--danger' onClick={() => {
                                        setItemModal(null)
                                        setItemModal(item)
                                        setDeleteModal(true)
                                      }}>
                                        <i className=" icon-box-archive"></i>
                                        <span>Archiver</span>
                                      </button>
                                      
                                    </div>

                                  </div>
                                ))
                              }

                            </div>
                          </div>
                        )
                      ) : (
                        <div className={"kbn-empty-status"}>
                          <div className='kbn-empty-status__illu'>
                            <MeditationCuate/>
                          </div>
                          <div className='kbn-empty-status__container'>
                            <strong>La liste est vide.</strong>
                          </div>
                        </div>
                      )
                    }
                  </>
                ) : null
              )
            )
          }
          {
            itemModal && deleteModal ? <DeleteModal item={itemModal} closeFnc={setDeleteModal} /> : null
          }
          {
            importModal ? <ImportModal closeFnc={setImportModal}/> : null
          }
        </div>
      </MenuLayout>
    )
}
  